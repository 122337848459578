import React, { useEffect, useRef } from "react";
import classes from "../../../styles/components/EditSettingsModal.module.css";
import { passwordVisibilityToggle } from "../../../helpers/passwordVisibilityToggle";
import { useDispatch } from "react-redux";
import {
  changePasswordSettings,
  setUserData,
  upadateUserDetails,
} from "../../../store/actions/user";
import notify from "../../../helpers/toast";

const EditSettingModal = (props) => {
  const currentPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmNewPasswordRef = useRef();
  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();
  const dispatch = useDispatch();
  const local = localStorage.getItem("auth");
  const data = JSON.parse(local);

  const closeOnEscapeButton = (e) => {
    if (e.keyCode === 27) {
      props.onClose();
    }
  };
  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeButton);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeButton);
    };
  });

  const closeModalHandler = () => {
    props.onClose();
  };

  if (!props.showModal) {
    return null;
  }
  const saveChangesButtonHandler = () => {
    const firstName = firstNameInputRef.current.value;
    const lastName = lastNameInputRef.current.value;
    const oldPassword = currentPasswordRef.current.value;
    const newPassword = newPasswordRef.current.value;
    const confirmNewPassword = confirmNewPasswordRef.current.value;
    if (!firstName || !lastName) {
      notify({ text: "Please fill all the input fields." });;
      return;
    }
    if (newPassword !== confirmNewPassword) {
      notify({ text: "Passwords must match." });
      return;
    }
    if (oldPassword && newPassword && confirmNewPassword && oldPassword !==newPassword) {
      dispatch(changePasswordSettings(oldPassword, newPassword));
      props.onClose();
    }
    if (newPassword === confirmNewPassword && oldPassword !== newPassword && confirmNewPassword !== oldPassword) {
      dispatch(upadateUserDetails(firstName, lastName));
      props.onClose();
      const auth = {
        firstName: firstName ? firstName : data.firstName,
        lastName: lastName ? lastName : data.lastName,
        credits: data.credits,
        email: data.email,
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      };
      localStorage.setItem("auth", JSON.stringify(auth));
      dispatch(setUserData(auth));
    }
    if (oldPassword === newPassword && oldPassword === confirmNewPassword) {
      notify({text: "Passwords must not be the same"});
    }
  };
    

  return (
    <div className={classes.modalWrapper}>
      <div className={classes.headerWrapper}>
        <div className={classes.iconWrapper}>
          <i className="icon-Settings"></i>
          <h4>Settings</h4>
        </div>
        <div className={classes.buttonWrapper}>
          <button onClick={closeModalHandler} className={classes.whiteButton}>
            Discard Changes
          </button>
          <button
            className={classes.purpleButton}
            onClick={saveChangesButtonHandler}
          >
            Save Changes
          </button>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.inputWrapper}>
          <h1 className={classes.purpleH1}>Change Information</h1>
          <div className={classes.fieldWrapper}>
            <label>First Name</label>
            <input
              ref={firstNameInputRef}
              defaultValue={data.firstName}
              contentEditable="true"
            ></input>
          </div>
          <div className={classes.fieldWrapper}>
            <label>Last Name</label>
            <input
              ref={lastNameInputRef}
              defaultValue={data.lastName}
              contentEditable="true"
            ></input>
          </div>
          <div>
            <label>Email</label>
            <input
              defaultValue={data.email}
              contentEditable="false"
              disabled="disabled"
            ></input>
          </div>
        </div>
        <div className={classes.inputWrapper}>
          <h1 className={classes.purpleH1}>Change Password</h1>
          <div className={classes.passwordInput}>
            <label>Current Password</label>
            <div className={classes.fieldWrapper}>
              <div className={classes.passwordInput}>
                <input
                  className="password-input"
                  type="password"
                  id="password1"
                  name="password"
                  ref={currentPasswordRef}
                ></input>
                <i
                  className="icon-eye-blocked"
                  id="togglePassword"
                  onClick={passwordVisibilityToggle}
                ></i>
              </div>
            </div>
          </div>
          <div className={classes.passwordInput}>
            <label>New Password</label>
            <div className={classes.fieldWrapper}>
              <input
                className="password-input"
                placeholder=""
                type="password"
                id="password2"
                name="password"
                ref={newPasswordRef}
              ></input>
              <i
                className="icon-eye-blocked"
                id="togglePassword"
                onClick={passwordVisibilityToggle}
              ></i>
            </div>
          </div>
          <label>Confirm New Password</label>
          <div className={classes.passwordInput}>
            <input
              className="password-input"
              placeholder=""
              type="password"
              id="password3"
              name="password"
              ref={confirmNewPasswordRef}
            ></input>
            <i
              className="icon-eye-blocked"
              id="togglePassword"
              onClick={passwordVisibilityToggle}
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSettingModal;
