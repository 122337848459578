import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import App from "./App";
import { store } from "./store";
import { Router } from "react-router";
import { setUserData } from "./store/actions/user";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const createBrowserHistory = require("history").createBrowserHistory;

export const history = createBrowserHistory();

const local = localStorage.getItem("auth");
const data = JSON.parse(local);

if (local) {
  store.dispatch(setUserData(data));
}

ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey="6Lc6ERAiAAAAAOhxrdrIarugPC80lVgopylxGxRf"
    useRecaptchaNet={true}
  >
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </GoogleReCaptchaProvider>,
  document.getElementById("root")
);
