import classes from "../../../styles/components/AddDeviceModal.module.css";
import React, { useEffect, useState } from "react";
import VerificationInput from "react-verification-input";
import { useDispatch } from "react-redux";
import { activateDevice } from "../../../store/actions/devices";
import notify from "../../../helpers/toast";

const AddDeviceModal = (props) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  const addDeviceButtonHandler = () => {
    if (props.showModal) {
      const deviceHotp = value;
      console.log(value);
      if (deviceHotp.length < 6) {
        notify({ text: "Please fill all the input fields" });
      } else {
        dispatch(activateDevice(deviceHotp));
        props.onClose();
      }
    }
  };

  const closeOnEscapeButton = (e) => {
    if (e.keyCode === 27) {
      props.onClose();
    }
  };
  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeButton);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeButton);
    };
  });
  useEffect(() => {
    document.body.addEventListener("keydown", confirmOnEnterPress);
    return function cleanup() {
      document.body.removeEventListener("keydown", confirmOnEnterPress);
    };
  });
  const confirmOnEnterPress = (event) => {
    if (event.keyCode === 13) {
      addDeviceButtonHandler();
    }
  };

  const closeModalHandler = () => {
    props.onClose();
  };

  if (!props.showModal) {
    return null;
  }

  return (
    <div className={classes.modalBackground}>
      <div className={classes.cardWrapper}>
        <div className={classes.h1}>
          <h1>Add Device</h1>
        </div>
        <div className={classes.paragraph}>
          <p>Please enter code you see on your device you want to add.</p>
        </div>
        <fieldset className={classes.flexitems}>
          <VerificationInput
            length={6}
            autoFocus={true}
            removeDefaultStyles={true}
            placeholder=""
            value={value}
            onChange={setValue}
            classNames={{
              container: classes.container,
              character: classes.character,
              characterInactive: "character--inactive",
              characterSelected: "character--selected",
            }}
          />
        </fieldset>
        <div className={classes.buttonWrapper}>
          <button onClick={closeModalHandler} className={classes.cancelButton}>
            Cancel
          </button>
          <button
            className={classes.purpleButton}
            onClick={addDeviceButtonHandler}
          >
            Add Device
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddDeviceModal;
