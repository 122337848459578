import React from "react";
import mainLogo from "../../../assets/images/logo-full.png";
import classes from "../../../styles/components/HeaderDashboard.module.css";
import logout from "../../../assets/images/LogoutVector.png";
import { useHistory } from "react-router-dom";

const HeaderDashboard = () => {
  const history = useHistory();

  const logoutButtonHandler = () => {
    localStorage.removeItem("auth");
    history.push("./");
  };

  const logoButtonHandler = () => {
    history.push("/devices-dashboard");
  };

  return (
    <div className={classes.headerWrapper}>
      <img
        src={mainLogo}
        alt="logo"
        className={classes.logo}
        onClick={logoButtonHandler}
      ></img>

      <div className={classes.dashboardButtonWrapper}>
        <button onClick={logoutButtonHandler} className={classes.logoutButton}>
          Logout
        </button>
        <img
          src={logout}
          alt="logout-button"
          className={classes.logoutVector}
          onClick={logoutButtonHandler}
        ></img>
      </div>
    </div>
  );
};

export default HeaderDashboard;
