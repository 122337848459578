import React from "react";
import { useHistory } from "react-router-dom";
import classes from "../../../styles/components/MobileLogoutModal.module.css";

const MobileLogoutModal = (props) => {
  const local = localStorage.getItem("auth");
  const data = JSON.parse(local);
  const history = useHistory();

  if (!props.showMobileModal) {
    return null;
  }
  const logoutButtonHandler = () => {
    localStorage.removeItem("auth");
    history.push("./");
  };
  const closeOnOutsideClickButtonHandler = (e) => {
    const background = document.getElementById("mobileBackground");
    const mobileHeader = document.getElementById("mobileHeader")
    console.log(mobileHeader)
    if (e.target === background || e.target === mobileHeader) {
      props.onClose();
    }
  };

  return (
    <div
      className={classes.modalBackground}
      id="mobileBackground"
      onClick={closeOnOutsideClickButtonHandler}
    >
      <div className={classes.modalWrapper}>
        <div className={classes.balanceWrapper}>
          <p>Your balance is: {data.credits}</p>
        </div>
        <button className={classes.buttonWrapper} onClick={logoutButtonHandler}>
          Logout <i className="icon-LogoutIcon"></i>
        </button>
      </div>
    </div>
  );
};

export default MobileLogoutModal;
