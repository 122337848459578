import { axiosInstance } from "../../helpers/utility";
import * as actionTypes from "./actionTypes";
import { getDevices } from "./user";
import { setDevices } from "./user";
import notify from "../../helpers/toast";

export const addDevice = (
  device,
  deviceId,
  expirationDate,
  lastActivity,
  model,
  status,
  type,
  user,
  id
) => {
  return async (dispatch) => {
    const data = {
      device,
      deviceId,
      expirationDate,
      lastActivity,
      model,
      status,
      type,
      user,
    };
    console.log(data);
    axiosInstance
      .put(`/v1/devices/${id}`, {
        data,
      })
      .then((res) => {
        console.log("SUCCESS DEVICE ADD RESPONSE: ", res);
      })
      .catch((err) => {
        console.log("DEVICE ADD ERROR RESPONSE: ", err);
      });
  };
};

export const putDevice = (id) => {
  return async (dispatch) => {
    axiosInstance
      .put(`/v1/devices/${id}`)
      .then((res) => {
        console.log("SUCCESS PUTTING DEVICE RESPONSE", res);
      })
      .catch((err) => {
        console.log("PUT DEVICE ERROR RESPONSE", err);
      });
  };
};
export const getM3U = (deviceId) => {
  return async (dispatch) => {
    axiosInstance
      .get(`/v1/devices/getAllSources/${deviceId}`)
      .then((res) => {
        console.log(res.data);
        dispatch(setM3uXtreamList(res.data));
        console.log("SUCCESS GETTING M3U RESPONSE", res);
      })
      .catch((err) => {
        console.log("GET M3U ERROR RESPONSE", err);
      });
  };
};
export const putM3U = (alias, url, deviceId) => {
  const data = {
    alias,
    url,
    deviceId
  };
  return async (dispatch) => {
    axiosInstance
      .post(`/v1/m3u8/addSource`, { data })
      .then((res) => {
        notify({
          text: "List added successfully! Please wait about 30 seconds, before launching the TV application.",
        });
        dispatch(getM3U(deviceId));
        console.log("SUCCESS PUTTING M3U RESPONSE", res);
      })
      .catch((err) => {
        notify({ text: "Requested list does not exist." });
        console.log("PUT M3U ERROR RESPONSE", err);
      });
  };
};
export const deleteM3U = (deviceId, url) => {
  const data = { deviceId, url };
  return async (dispatch) => {
    axiosInstance
      .post(`/v1/m3u8/removeSource`, {
        data,
      })
      .then((res) => {
        dispatch(getM3U(deviceId));
        console.log("SUCCESS DELETING M3U RESPONSE", res);
      })
      .catch((err) => {
        console.log("DELETING M3U ERROR RESPONSE", err);
      });
  };
};

export const addXtreamUserSource = (
  deviceId,
  domain,
  password,
  port,
  protocol,
  username,
) => {
  const data = { deviceId, domain, password, port, protocol, username };
  return async (dispatch) => {
    axiosInstance
      .post(`/v1/xtream/addUserSource`, {
        data,
      })
      .then((res) => {
        console.log("SUCCESS ADDING XTREAM USER SOURCE RESPONSE", res);
        dispatch(getM3U(deviceId));
        notify({
          text: "Please wait, this might take a while...",
          autoClose: 8000,
        });
      })
      .catch((err) => {
        notify({
          text: "Requested source does not exist.",
        });
        console.log(
          "ADDING XTREAM USER SOURCE ERROR RESPONSE",
          err.response.data.message
        );
        notify({ text: err.response.data.message });
      });
  };
};

export const deleteXtream = (deviceId) => {
  return async (dispatch) => {
    axiosInstance
      .get(`/v1/xtream/removeUserSource/${deviceId}`)
      .then((res) => {
        dispatch(getM3U(deviceId));
        console.log("SUCCESS GETTING M3U RESPONSE", res);
      })
      .catch((err) => {
        console.log("GET M3U ERROR RESPONSE", err);
      });
  };
};

export const refreshContent = (deviceId) => {
  return async () => {
    axiosInstance
      .get(`/v1/xtream/refreshContent/${deviceId}`)
      .then((res) => {
        console.log("SUCCESS REFRESHING CONTENT RESPONSE", res);
      })
      .catch((err) => {
        console.log("ERROR REFRESHING CONTENT RESPONSE", err);
      });
  };
};

export const activateDevice = (deviceHotp) => {
  const data = {
    deviceHotp,
  };
  return async (dispatch) => {
    axiosInstance
      .post(`/v1/devices/activateDevice`, {
        data,
      })
      .then((res) => {
        console.log("SUCCESS ACTIVATING DEVICE RESPONSE", res);
        dispatch(getDevices());
      })
      .catch((err) => {
        notify({ text: "Requested device does not exist" });
        console.log("ACTIVATE DEVICE ERROR RESPONSE", err);
      });
  };
};

export const renameDevice = (device, deviceId, devicesAll) => {
  const data = {
    device,
    deviceId,
  };
  return async (dispatch) => {
    axiosInstance
      .post(`/v1/devices/renameDevice`, {
        data,
      })
      .then((res) => {
        notify({ text: "Device renamed successfully!" });
        dispatch(getDevices());
        dispatch(setDevices(devicesAll));
      })
      .catch((err) => {
        console.log("RENAME DEVICE ERROR RESPONSE", err);
      });
  };
};
export const changeAlias = (alias, deviceId, url) => {
  const data = {
    alias,
    deviceId,
    url,
  };
  return async (dispatch) => {
    axiosInstance
      .post(`/v1/m3u8/changeAlias`, {
        data,
      })
      .then((res) => {
        notify({ text: "Name changed successfully!" });
        dispatch(getM3U(deviceId));
      })
      .catch((err) => {
        console.log("CHANGE ALIAS ERROR RESPONSE", err);
      });
  };
};

export const activateSource = (xtream, deviceId, url) => {
  const data = {
    xtream,
    deviceId,
    url,
  };
  return async (dispatch) => {
    axiosInstance
      .post(`/v1/devices/activateSource`, {
        data,
      })
      .then((res) => {
        notify({ text: "Source activated successfully!" });
        dispatch(getM3U(deviceId));
      })
      .catch((err) => {
        console.log("ACTIVATE SOURCE ERROR RESPONSE", err);
      });
  };
};
export const deactivateSource = (deviceId) => {
  return async (dispatch) => {
    axiosInstance
      .get(`/v1/devices/deactivateSource/${deviceId}`
      )
      .then((res) => {
        notify({ text: "Source deactivated successfully!" });
        dispatch(getM3U(deviceId));
      })
      .catch((err) => {
        console.log("DEACTIVATE SOURCE ERROR RESPONSE", err);
      });
  };
};

export const getAllRadios = (deviceId) => {
  return async (dispatch) => {
    setLoadingState(true)
    await axiosInstance
      .get(`/v1/devices/getRadios/${deviceId}`)
      .then((res) => {
        dispatch(setRadiosList(res.data));
        console.log("SUCCESS GETTING RADIOS RESPONSE", res);
        setLoadingState(false);
      })
      .catch((err) => {
        console.log("GET RADIOS ERROR RESPONSE", err);
      });
  };
};

export const postRadio = (deviceId, name, streamIcon, streamSource) => {
  const data = {
    deviceId,
    name,
    streamIcon,
    streamSource,
  };
  return async (dispatch) => {
    axiosInstance
      .post(`/v1/radio`, {
        data,
      })
      .then((res) => {
        console.log("SUCCESS POSTING RADIO SOURCE RESPONSE", res);
        notify({ text: "Radio added successfully!" });
        dispatch(getAllRadios(deviceId));
      })
      .catch((err) => {
        console.log("POSTING RADIO SOURCE ERROR RESPONSE", err);
      });
  };
};

export const addRadioToDevice = (deviceId, radioName) => {
  const data = {
    deviceId,
    radioName,
  };
  return async (dispatch) => {
    axiosInstance
      .post(`/v1/radio/addToDevice`, {
        data,
      })
      .then((res) => {
        console.log("SUCCESS POSTING RADIO SOURCE RESPONSE", res);
        notify({ text: "Radio bound to device successfully!" });
        dispatch(getAllRadios(deviceId));
      })
      .catch((err) => {
        console.log("POSTING RADIO SOURCE ERROR RESPONSE", err);
      });
  };
};

export const removeRadioFromDevice = (deviceId, radioName) => {
  const data = {
    deviceId,
    radioName,
  };
  return async (dispatch) => {
    axiosInstance
      .post(`/v1/radio/removeFromDevice`, {
        data,
      })
      .then((res) => {
        console.log("SUCCESS REMOVING RADIO SOURCE RESPONSE", res);
        notify({ text: "Radio unbound from device successfully!" });
        dispatch(getAllRadios(deviceId));
      })
      .catch((err) => {
        console.log("POSTING RADIO SOURCE ERROR RESPONSE", err);
      });
  };
};

export const deleteRadio = (deviceId, id) => {
  return async (dispatch) => {
    axiosInstance
      .delete(`/v1/radio/${id}/${deviceId}`, {})
      .then((res) => {
        console.log("SUCCESS DELETING RADIO SOURCE RESPONSE", res);
        notify({ text: "Radio deleted successfully!" });
        dispatch(getAllRadios(deviceId));
      })
      .catch((err) => {
        console.log("DELETING RADIO SOURCE ERROR RESPONSE", err);
      });
  };
};

export const updateRadio = (id, name, streamIcon, streamSource) => {
  const data = {
    name,
    streamIcon,
    streamSource,
  };
  return async (dispatch) => {
    axiosInstance
      .put(`/v1/radio/${id}`, { data })
      .then((res) => {
        console.log("SUCCESS UPDATING RADIO NAME RESPONSE", res);
        notify({ text: "Radio updated successfully!" });
        dispatch(getAllRadios());
      })
      .catch((err) => {
        console.log("UPDATING RADIO SOURCE ERROR RESPONSE", err);
      });
  };
};

export const setM3uXtreamList = (m3uXtreamList) => {
  return {
    type: actionTypes.SET_M3U_XTREAM_LIST,
    m3uXtreamList,
  };
};

export const setRadiosList = (radiosList) => {
  return {
    type: actionTypes.SET_RADIOS,
    radiosList,
  };
};
export const setLoadingState = (isLoading) => {
  return {
    type: actionTypes.SET_LOADING,
    isLoading,
  };
};
