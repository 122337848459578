import React, { useRef } from "react";
import classes from "../../styles/components/PasswordConfirmation.module.css";
import { passwordVisibilityToggle } from "../../helpers/passwordVisibilityToggle";
import { passwordChangeVerification } from "../../store/actions/user";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import notify from "../../helpers/toast";

const PasswordConfirmation = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const passwordRef = useRef();
  const repeatPasswordRef = useRef();

  const confirmNewPasswordButtonHandler = () => {
    const newPassword = passwordRef.current.value;
    const repeatNewPassword = repeatPasswordRef.current.value;
    if (newPassword === repeatNewPassword) {
      dispatch(passwordChangeVerification({ token }, newPassword));
    } else {
      notify({ text: "Passwords must match." });
    }
  };

  console.log(token);

  return (
    <section>
      <div className={classes.cardWrapper}>
        <div className={classes.h1}>
          <h1>Password Change</h1>
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.inputWrapper}>
            <label htmlFor="password">Password</label>
            <div className={classes.inputWrapperInput}>
              <input
                className="password-input"
                type="password"
                required
                id="password1"
                ref={passwordRef}
              ></input>
              <i
                className="icon-eye-blocked"
                id="togglePassword"
                onClick={passwordVisibilityToggle}
              ></i>
            </div>
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="password">Confirm password</label>
            <div className={classes.inputWrapperInput}>
              <input
                type="password"
                required
                className="password-input"
                id="password2"
                ref={repeatPasswordRef}
              ></input>
              <i
                className="icon-eye-blocked"
                id="togglePassword"
                onClick={passwordVisibilityToggle}
              ></i>
            </div>
          </div>
          <div className={classes.buttonWrapper}>
            <button
              className={classes.createAccButton}
              onClick={confirmNewPasswordButtonHandler}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PasswordConfirmation;
