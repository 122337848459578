import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "../../../styles/components/PlanModal.module.css";
import { getAllEnabledSubscriptions } from "../../../store/actions/user";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation, Pagination, Keyboard } from "swiper";
import { subscribeDevice } from "../../../store/actions/user";
import { setUserData } from "../../../store/actions/user";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/effect-coverflow/effect-coverflow.min.css";
import notify from "../../../helpers/toast";

const PlanModal = (props) => {
  const dispatch = useDispatch();
  const plansList = useSelector((state) => state.plansList.content);
  const local = localStorage.getItem("auth");
  const data = JSON.parse(local);
  const yearsLeft = props.eleInfo.expirationDate.substring(0, 4);

  const closeModalOnEscapeButton = (e) => {
    if (e.keyCode === 27) {
      props.onClosePlan();
    }
  };
  useEffect(() => {
    document.body.addEventListener("keydown", closeModalOnEscapeButton);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeModalOnEscapeButton);
    };
  });

  if (props.showPlanModal) {
    const mobileHeader = document.getElementById("mobileHeader");
    console.log(mobileHeader);
    mobileHeader.className = classes.mobileHeaderHidden;
  }

  console.log(yearsLeft);
  useEffect(() => {
    if (props.showPlanModal) {
      dispatch(getAllEnabledSubscriptions());
    }
  }, [props.showPlanModal]);

  if (!props.showPlanModal) {
    return null;
  }

  const closeModal = (event) => {
    const background = document.getElementById("background");
    const wrapper = document.getElementById("modalWrapper");
    if (event.target === background || event.target === wrapper) {
      props.onClosePlan();
    }
  };

  const closeButtonHandler = () => {
    props.onClosePlan();
    const mobileHeader = document.getElementById("mobileHeader");
    mobileHeader.classList.toggle(classes.mobileHeader);
  };
  const deviceId = props.deviceId;

  const subscribeButtonHandler = (ele) => {
    console.log(ele);
    let subscriptionName = ele.name;
    let price = ele.price;
    if (yearsLeft > 3000) {
      notify({ text: "Unlimited subscription already purchased." });
      return;
    } else {
      dispatch(subscribeDevice(deviceId, subscriptionName));
      const auth = {
        firstName: data.firstName,
        lastName: data.lastName,
        credits: data.credits < price ? (notify({ text: "Not enough credits." }) && data.credits) : data.credits - price,
        email: data.email,
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      };
      localStorage.setItem("auth", JSON.stringify(auth));
      dispatch(setUserData(auth));
      props.onClosePlan();
    }
  };

  console.log(plansList);
  return (
    <div className={classes.background} id='background' onClick={closeModal}>
      <Swiper
        modules={[Navigation, Pagination, Keyboard]}
        // spaceBetween={50}
        slidesPerView={3}
        keyboard={true}
        navigation={true}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        loop={false}
        autoHeight={true}
        breakpoints={{
          1024: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
        }}
      >
        {plansList
          ? plansList.map((ele, index) => {
              return (
                <SwiperSlide>
                  <div className={classes.swiperSlide}>
                    <div className={classes.modalHeader} key={index}>
                      <div className={classes.buttonWrapper}>
                        <button
                          className={classes.xButton}
                          onClick={closeButtonHandler}
                        >
                          X
                        </button>
                      </div>
                      <h2>{ele.name}</h2>
                      <i
                        className={
                          ele.time === 3
                            ? "icon-3"
                            : ele.time === 6
                            ? "icon-6"
                            : ele.time === 100
                            ? "icon-Vector"
                            : ele.time === 12
                            ? "icon-icon12"
                            : "icon-Vector"
                        }
                      ></i>
                      <h2>
                        {ele.time === 100
                          ? "SUBSCRIPTION"
                          : `${
                              ele.timeUnit === "MONTH" ? "Months" : "Unlimited"
                            }`}
                      </h2>
                    </div>
                    <div className={classes.modalContent}>
                      <p className={classes.paragraph}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Et neque tincidunt senectus mattis vitae semper. Nec
                        arcu nunc purus euismod. Ac duis at urna, nulla aliquam
                        in tempus quisque. Nunc sapien aliquam venenatis
                        venenatis lorem.
                      </p>
                      <p className={classes.credit}>{ele.price} Credit</p>
                      <button
                        className={classes.button}
                        onClick={() => subscribeButtonHandler(ele)}
                      >
                        Active Now
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })
          : null}
      </Swiper>
    </div>
  );
};

export default PlanModal;
