import { toast } from "react-toastify";

const notify = ({text, type = "info", autoClose = 6000,} = {}) =>
  toast[type](text, {
    position: "top-center",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    autoClose: autoClose,
  });

export default notify;
