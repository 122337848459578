import React from "react";
import classes from "../../styles/components/LoginSidebar.module.css";
import mainLogo from "../../assets/images/logo-full.png";

const LoginSidebar = (props) => {
  return (
      <section className={classes.image}>
        <div className={classes.sidebar}>
          <img src={mainLogo} alt="logo" className={classes.logo}></img>
          <h2 className={classes.header}>Welcome to Lumen TV,</h2>
          <p className={classes.paragraph}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit urna,
            magna aliquam egestas molestie. Condimentum ultricies molestie eu
            eget viverra aliquam. Nam purus urna, aliquam imperdiet elementum at
            placerat. Faucibus pellentesque malesuada enim fringilla posuere at
            felis dui.
          </p>
        </div>
        {props.children}
      </section>
  );
};

export default LoginSidebar;
