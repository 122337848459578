import React, { useEffect, useRef } from "react";
import classes from "../../../styles/components/AddXtreamModal.module.css";
import { addXtreamUserSource } from "../../../store/actions/devices";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import notify from "../../../helpers/toast";

const AddXtreamModal = (props) => {
  const dispatch = useDispatch();
  const usernameInputRef = useRef();
  const passwordInputRef = useRef();
  const serverURLInputRef = useRef();
  const portInputRef = useRef();
  const protocolInputRef = useRef();

  const closeModalHandler = () => {
    props.onCloseXtreamModal();
  };
  const closeOnEscapeButton = (e) => {
    if (e.keyCode === 27) {
      props.onCloseXtreamModal();
    }
  };
  const addOnEnterButton = (e) => {
    if (props.showXtreamModal) {
      if (e.keyCode === 13) {
        addUserButtonHandler();
      }
    }
  };
  useEffect(() => {
    document.body.addEventListener("keydown", addOnEnterButton);
    return function cleanup() {
      document.body.removeEventListener("keydown", addOnEnterButton);
    };
  });
  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeButton);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeButton);
    };
  });
  if (!props.showXtreamModal) {
    return null;
  }
  const deviceId = props.deviceid;
  const addUserButtonHandler = () => {
    const domain = serverURLInputRef.current.value;
    const password = passwordInputRef.current.value;
    const port = portInputRef.current.value;
    const username = usernameInputRef.current.value;
    const protocol = protocolInputRef.current.value;
    if (!domain || !password || !port || !username) {
      if (props.showXtreamModal) {
        notify({ text: "Please fill all the input fields." });
      }
    } else {
      dispatch(
        addXtreamUserSource(
          props.deviceId,
          domain,
          password,
          port,
          protocol,
          username,
          deviceId
        )
      );
      props.onCloseXtreamModal();
    }
  };
  const xButtonHandler = () => {
    props.onCloseXtreamModal();
  };

  return (
    <div className={classes.modalBackground} id="xtreamModal">
      <div className={classes.modalWrapper}>
        <div className={classes.contentWrapper}>
          <div>
            <button className={classes.xButton} onClick={xButtonHandler}>
              X
            </button>
            <h1 className={classes.h1}>Add Xtream User</h1>
          </div>
          <div className={classes.inputWrapper}>
            <label>Username</label>
            <input type="username" ref={usernameInputRef}></input>
          </div>
          <div className={classes.inputWrapper}>
            <label>Password</label>
            <input type="password" ref={passwordInputRef}></input>
          </div>
          <div className={classes.inputWrapper}>
            <label>Domain</label>
            <input ref={serverURLInputRef}></input>
          </div>
          <div className={classes.inputWrapper}>
            <label>Protocol</label>
            <input ref={protocolInputRef}></input>
          </div>
          <div className={classes.inputWrapper}>
            <label>Port</label>
            <input ref={portInputRef}></input>
          </div>
          <div className={classes.buttonWrapper}>
            <div>
              <button
                className={classes.blackButton}
                onClick={closeModalHandler}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className={classes.purpleButton}
                onClick={addUserButtonHandler}
              >
                Add Xtream user
              </button>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
};

export default AddXtreamModal;
