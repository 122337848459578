export const SET_USER = "SET_USER";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_DEVICES = "SET_DEVICES"
export const SET_M3U_XTREAM_LIST = "SET_M3U_XTREAM_LIST";
export const SET_ERROR_CODE = "SET_ERROR_CODE";
export const SET_SUCCESS_REGISTRATION = "SET_SUCCESS_REGISTRATION";
export const SET_PLANS = "SET_PLANS";
export const SET_RADIOS = "SET_RADIOS";
export const SET_LOADING = "SET_LOADING";

