import React, { Fragment, useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import avatar from "../../../assets/images/AvatarVector.png";
import classes from "../../../styles/components/SidebarDashboard.module.css";
import mainLogo from "../../../assets/images/logo-full.png";
import MobileLogoutModal from "../Modals/MobileLogoutModal";

const SidebarDashboard = () => {
  const userData = useSelector((state) => state.userData);
  const imageRef = useRef();
  const [showMobileModal, setShowMobileModal] = useState(false);

  const showMobileModalButtonHandler = () => {
    if (showMobileModal) {
      setShowMobileModal(false);
    } else {
      setShowMobileModal(true);
    }
  };
  const hamburgerButtonHandler = () => {
    const menu = document.getElementById("mobileMenu");
    const header = document.getElementById("header");
    const logo = document.getElementById("mobileLogo");
    menu.classList.toggle(classes.mobileMenu);
    header.classList.toggle(classes.sidebarOpen);
    logo.classList.toggle(classes.hidden);
  };

  const menuRef = useRef(null);

  useEffect(() => {
    const menu = document.getElementById("mobileMenu");
    const header = document.getElementById("header");
    const logo = document.getElementById("mobileLogo");
    const hamburger = document.getElementById("hamburger");
    const handleOutsideClick = (event) => {
      if (hamburger.contains(event.target)) {
        return;
      }
      if (menuRef && !menuRef.current.contains(event.target)) {
        header.className = classes.wrapper;
        menu.className = classes.hidden;
        logo.className = classes.logo;
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [menuRef.current]);

  return (
    <Fragment>
      <div className={classes.mobileHeader} id="mobileHeader">
        <div className={classes.hamburgerWrapper}>
          <button
            className={classes.hamburger}
            onClick={hamburgerButtonHandler}
            id="hamburger"
          >
            <i className="icon-HamburgerWhite"></i>
          </button>
          <span className={classes.hidden} id="mobileMenu">
            Menu
          </span>
        </div>
        <div className={classes.mobileLogoWrapper}>
          <img
            src={mainLogo}
            alt="logo"
            className={classes.logo}
            id="mobileLogo"
          ></img>
        </div>
        <div className={classes.searchBar}>
          <img
            src={avatar}
            className={classes.mobileAvatar}
            alt=""
            onClick={showMobileModalButtonHandler}
          ></img>
        </div>
      </div>
      <div className={classes.wrapper} id="header" ref={menuRef}>
        <label className={classes.avatarWrapper} htmlFor="image">
          <input
            type="file"
            name="image"
            id="image"
            style={{ display: "none" }}
            ref={imageRef}
          />
          <img className={classes.avatar} src={avatar} alt=""></img>
        </label>

        <div className={classes.paragraph}>
          <p>{userData.firstName + " " + userData.lastName}</p>
          <h3>
            Balance: <span className={classes.purple}>{userData.credits}</span>
          </h3>
        </div>

        <div className={classes.buttonWrapper}>
          <div className={classes.firstIcon}>
            <NavLink
              to="/devices-dashboard"
              className={(active) =>
                active ? classes.current : classes.navLink
              }
              id="button1"
            >
              <i className="icon-devices"></i>Devices
            </NavLink>
          </div>
          <div style={{ display: "none" }}>
            <NavLink
              to="/credit-dashboard"
              className={(active) =>
                active ? classes.current : classes.navLink
              }
              id="button2"
            >
              <i className="icon-subription"></i>Credit
            </NavLink>
          </div>
          <div>
            <NavLink
              to="/settings-dashboard"
              className={(active) =>
                active ? classes.current : classes.navLink
              }
              id="button3"
            >
              <i className="icon-Settings"></i>Settings
            </NavLink>
          </div>
        </div>
      </div>
      <MobileLogoutModal
        showMobileModal={showMobileModal}
        onClose={() => setShowMobileModal(false)}
      />
    </Fragment>
  );
};

export default SidebarDashboard;
