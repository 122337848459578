import React, { useEffect, useState } from "react";
import classes from "../../styles/components/DashboardDevices.module.css";
import AddDeviceModal from "./Modals/AddDeviceModal";
import DeviceDetailsModal from "./Modals/DeviceDetailsModal";
import DeleteModal from "./Modals/DeleteModal";
import { getDevices } from "../../store/actions/user";
import { useDispatch, useSelector } from "react-redux";
import { deleteDevice } from "../../store/actions/user";
import { formattedDate } from "../../helpers/formattedDate";
import { renameDevice } from "../../store/actions/devices";

const DashboardDevices = () => {
  const [filteredDevices, setFilteredDevices] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showDeviceDetailModal, setShowDeviceDetailModal] = useState(false);
  const [filter, setFilter] = useState("");
  const [eleInfo, setEleInfo] = useState("");
  const [devices, setDevices] = useState([]);
  const [deviceInfo, setDeviceInfo] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const devicesAll = useSelector((state) => state.devicesAll);
  const dispatch = useDispatch();

  const showModalHandler = () => {
    setShowModal(true);
  };

  useEffect(() => {
    dispatch(getDevices());
  }, []);

  useEffect(() => {
    setDevices(devicesAll);
    setFilteredDevices(devicesAll);
  }, [devicesAll]);

  const showDeviceModalHandler = (element) => {
    setShowDeviceDetailModal(true);
    setEleInfo(element);
  };

  const showDeviceDeleteModalHandler = (element) => {
    setShowDeleteModal(true);
    setDeviceInfo(element);
  };

  const deleteDevicesHandler = async () => {
    if (showDeleteModal) {
      dispatch(deleteDevice(deviceInfo.deviceId));
    }
  };

  const search = (objectDevice) => {
    for (const key in objectDevice) {
      if (
        String(objectDevice[key]).toLowerCase().includes(filter.toLowerCase())
      ) {
        return true;
      }
    }
  };

  useEffect(() => {
    let filteredDevices = devices.filter((ele) => search(ele));
    setFilteredDevices(filteredDevices);
  }, [filter]);

  const nameChangeHandler = (event, deviceId, devicesAll) => {
    dispatch(renameDevice(event.target.value, deviceId, devicesAll));
    const input = event.target;
    input.disabled = true;
  };
  const focusOnInputClick = (event) => {
    const input = event.target.parentNode.previousSibling;
    input.disabled = false;
    input.focus();
    console.log(input);
  };

  const searchIconButtonHandler = () => {
    const searchBar = document.getElementById("searchBar");
    searchBar.classList.toggle(classes.inputSearchNone);
    searchBar.focus()
  };

  return (
    <div className={classes.container}>
      <div
        className={`${classes.wrapper}
      ${showDeviceDetailModal ? classes.modalOpen : null}`}
      >
        <div className={classes.header}>
          <div className={classes.titleWrapper}>
            <i className="icon-devices"></i>
            <h1 className={classes.h1}>Active devices</h1>
            <button
              className={classes.searchIcon}
              onClick={searchIconButtonHandler}
            >
              <i className="icon-SearchIcon"></i>
            </button>
          </div>
          <div className={classes.buttonWrapper}>
            <input
              type="text"
              placeholder="Search..."
              className={classes.inputSearch}
              id="searchBar"
              onChange={(e) => setFilter(e.target.value)}
            ></input>
            <button className={classes.purpleButton} onClick={showModalHandler}>
              Add Device
            </button>
          </div>
        </div>
        <table className={classes.table}>
          <thead>
            <tr className={classes.devicesArr}>
              <td>Device</td>
              <td className={classes.type}>Type</td>
              <td className={classes.model}>Model</td>
              <td className={classes.lastActivity}>Last activity</td>
              <td>Exp. date</td>
              <td>Status</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {filteredDevices && filteredDevices ? (
              filteredDevices.map((element, index) => {
                return (
                  <tr className={classes.rows} key={index}>
                    <td className={classes.deviceName} title={element.device}>
                      <input
                        disabled="disabled"
                        onBlur={(e) => {
                          nameChangeHandler(e, element.deviceId, devicesAll);
                        }}
                        defaultValue={element.device}
                      ></input>
                      <button
                        onClick={focusOnInputClick}
                        title="Change device name"
                        className={classes.changeDeviceName}
                      >
                        <i className="icon-edit-button"></i>
                      </button>
                    </td>
                    <td className={`icon-TV ${classes.iconTV}`}></td>
                    <td className={classes.model}>{element.model}</td>
                    <td
                      style={{ whiteSpace: "pre-line" }}
                      className={classes.lastActivity}
                    >
                      {formattedDate(element.lastActivity)}
                    </td>
                    <td style={{ whiteSpace: "pre-line" }}>
                      {formattedDate(element.expirationDate)}
                    </td>
                    <td
                      className={
                        element.status === "ACTIVE"
                          ? classes.Active
                          : classes.Deactive
                      }
                    >
                      {element.status === "ACTIVE" ? "Active" : "Inactive"}
                    </td>
                    <td className={classes.removeButton}>
                      <button
                        title="Delete the device from the list"
                        onClick={() => showDeviceDeleteModalHandler(element)}
                        className="icon-remove"
                        key="iconRemove"
                      ></button>
                      <button
                        title="Show device details"
                        className="icon-eyeOpened"
                        key="icon-eye"
                        onClick={() => showDeviceModalHandler(element)}
                      ></button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>
                  <h1>Please Add Some Devices</h1>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <DeviceDetailsModal
          eleInfo={eleInfo}
          showDeviceModal={showDeviceDetailModal}
          onClose={() => setShowDeviceDetailModal(false)}
        />
        <AddDeviceModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          openDetailsModal={() => showDeviceModalHandler()}
        />
        <DeleteModal
          showDeleteModal={showDeleteModal}
          onCloseDeleteModal={() => setShowDeleteModal(false)}
          deviceInfo={deviceInfo}
          deleteDevices={deleteDevicesHandler}
          deviceMessage={
            deviceInfo &&
            `Are you sure you want to delete ${deviceInfo.device} from the list?`
          }
        />
      </div>
    </div>
  );
};

export default DashboardDevices;
