import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "../../../styles/components/DeviceDetailsModal.module.css";
import AddModal from "./AddModal";
import { useHistory } from "react-router-dom";
import PlanModal from "./PlanModal";
import notify from "../../../helpers/toast";
import {
  changeAlias,
  getM3U,
  renameDevice,
  deleteM3U,
  refreshContent,
  activateSource,
  deactivateSource,
  deleteXtream,
  getAllRadios,
  deleteRadio,
  updateRadio,
  removeRadioFromDevice,
  addRadioToDevice,
} from "../../../store/actions/devices";
import { formattedDate } from "../../../helpers/formattedDate";
import DeleteModal from "./DeleteModal";
import AddXtreamModal from "./AddXtreamModal";
import AddRadioModal from "./AddRadioModal";
import ReactTooltip from "react-tooltip";

const DeviceDetails = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [m3u, setM3u] = useState([]);
  const [m3UInfo, setM3UInfo] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showXtreamModal, setShowXtreamModal] = useState(false);
  const [showRadioModal, setShowRadioModal] = useState(false);
  const [radiosList, setRadiosList] = useState();
  const [radiosInfo, setRadiosInfo] = useState();
  const [comingFrom, setComingFrom] = useState();
  const dispatch = useDispatch();
  const m3uList = useSelector((state) => state.m3uXtreamList.m3u8Sources);
  const xtreamList = useSelector((state) => state.m3uXtreamList.xtreamSource);
  const devicesAll = useSelector((state) => state.devicesAll);
  const allRadios = useSelector((state) => state.radiosList);
  const isLoading = useSelector((state) => state.isLoading);
  const deviceId = props.eleInfo.deviceId;

  useEffect(() => {
    if (props.showDeviceModal) {
      dispatch(getM3U(deviceId));
      dispatch(getAllRadios(deviceId));
    }
  }, [deviceId]);

  useEffect(() => {
    if (props.showDeviceModal) {
      const allData = m3uList ? [...m3uList, xtreamList] : null;
      setM3u(allData);
    }
  }, [m3uList, xtreamList]);

  useEffect(() => {
    document.body.addEventListener("click", closeOnOutsideClick);
    return function cleanup() {
      document.body.removeEventListener("click", closeOnOutsideClick);
    };
  });

  //prevent back button on browser
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          history.push("/devices-dashboard");
        }
      }
    });
  }, [locationKeys]);

  const modalButtonHandler = () => {
    setShowModal(true);
  };
  const showPlanModalHandler = () => {
    setShowPlanModal(true);
  };
  const backButtonHandler = () => {
    props.onClose();
  };
  const showRadioModalButtonHandler = () => {
    setShowRadioModal(true);
  };

  const showDeleteModalHandler = (ele) => {
    setShowDeleteModal(true);
    setM3UInfo(ele);
    setRadiosInfo(ele);
  };

  const showXtreamModalHandler = () => {
    setShowXtreamModal(true);
  };

  const deleteM3UModalHandler = () => {
    const url = m3UInfo.source.url;
    if (showDeleteModal) {
      if (m3UInfo.source.url) {
        dispatch(deleteM3U(deviceId, url));
      } else {
        dispatch(deleteXtream(deviceId));
      }
    }
  };
  const refreshButtonHandler = () => {
    dispatch(refreshContent(deviceId));
    notify({ text: "Please wait,this might take a while..." });
  };

  if (!props.showDeviceModal) {
    return null;
  }

  const focusOnInputClick = (event) => {
    // const input = document.getElementById("inputName");
    const input = event.target;
    input.disabled = false;
    input.focus();
  };
  const nameChangeHandler = (event, deviceId, devicesAll) => {
    dispatch(renameDevice(event.target.value, deviceId, devicesAll));
    const input = event.target;
    input.disabled = true;
  };

  const m3uNameChangeHandler = (event, deviceId, url) => {
    dispatch(changeAlias(event.target.value, deviceId, url));
    const input = event.target;
    input.disabled = true;
  };
  const radioNameChangeHandler = (
    event,
    radioId,
    name,
    streamIcon,
    streamSource
  ) => {
    dispatch(
      updateRadio(radioId, event.target.value, streamIcon, streamSource)
    );
    const input = event.target;
    input.disabled = true;
  };
  const focusM3UOnInputClick = (event) => {
    const input = event.target.parentNode.previousSibling;
    input.disabled = false;
    input.focus();
  };

  const toggleSwitchTrue = (event, url, xtream) => {
    if (event.target.checked) {
      dispatch(activateSource(xtream, deviceId, url));
    } else {
      dispatch(deactivateSource(deviceId));
    }
  };
  const toggleRadio = (event, radioName) => {
    if (event.target.checked) {
      dispatch(addRadioToDevice(deviceId, radioName));
    } else {
      dispatch(removeRadioFromDevice(deviceId, radioName));
    }
  };

  const dropDownButton = (index) => {
    const dropdownQuerriesOpen = [
      ...document.querySelectorAll(`.${classes.dropdownOpen}`),
    ];
    const dropDownButton = document.getElementById(`dropdownButton${index}`);
    dropdownQuerriesOpen.forEach((ele) => {
      if (dropDownButton.nextSibling !== ele) {
        ele.classList.remove(classes.dropdownOpen);
      }
    });
    const dropdown = document.getElementById(`dropdownContent${index}`);
    dropdown.classList.toggle(classes.dropdownOpen);
  };

  const closeOnOutsideClick = (event) => {
    const dropdown = document.getElementsByClassName(classes.dropdownOpen);
    const dropDownButton = document.getElementsByClassName(
      classes.dropdownButton
    );
    for (const i in dropDownButton) {
      if (dropDownButton[i] === event.target) return;
    }
    if (!dropdown.length) return;
    const dropdownWrapper = document.getElementById("dropdown");
    if (event.target !== dropdownWrapper && event.target !== dropDownButton) {
      dropdown[0].classList.remove(classes.dropdownOpen);
    }
  };
  return (
    <div className={classes.modalWrapper}>
      <div className={classes.contentWrapper}>
        <div className={classes.headerWrapper}>
          <div className={classes.iconWrapper}>
            <i
              className='icon-backIcon'
              onClick={backButtonHandler}
              style={{ cursor: "pointer" }}
            ></i>
            <i className='icon-devices'></i>
            <h1 className={classes.h1}>
              {" "}
              <input
                id='inputName'
                disabled='disabled'
                defaultValue={`- ${props.eleInfo.device}`}
                onBlur={(e) => {
                  nameChangeHandler(e, props.eleInfo.deviceId, devicesAll);
                }}
              ></input>{" "}
              {/* <button
                onClick={focusOnInputClick}
                title='Change device name'
                className={classes.changeDeviceName}
              >
                <i className='icon-edit-button'></i>
              </button> */}
              <span
                className={
                  props.eleInfo.status === "ACTIVE"
                    ? classes.active
                    : classes.deactive
                }
              >
                {props.eleInfo.status}
              </span>
            </h1>
          </div>
          <div className={classes.buttonWrapper}>
            <button
              id='ActivateButton'
              className={
                props.eleInfo.status === "ACTIVE"
                  ? classes.purpleButtonDimmed
                  : classes.purpleButton
              }
              onClick={showPlanModalHandler}
              // data-tip={`You can't activate device until plan <br/> expires. Your plan expires on <br/> ${formattedDate(
              //   props.eleInfo.expirationDate
              // )}`}
            >
              Activate Device
            </button>
          </div>
        </div>
        <div className={classes.tableWrapper}>
          <h4>Mac ID: {props.eleInfo.macId}</h4>
          <h4>Device ID: {props.eleInfo.deviceId}</h4>
          <h4>Device: {props.eleInfo.model}</h4>
          <h4>
            Expiration date: {formattedDate(props.eleInfo.expirationDate)}
          </h4>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.headerWrapper}>
          <div className={classes.iconWrapper}>
            <i className='icon-Hamburger'></i>
            <h1 className={classes.h1}>M3U Lists</h1>
          </div>
          <div className={classes.buttonWrapperBottom}>
            <button
              className={classes.purpleButton}
              onClick={showXtreamModalHandler}
            >
              Add Xtream User
            </button>
            <button
              className={classes.purpleButton}
              onClick={modalButtonHandler}
            >
              Add M3U List
            </button>
          </div>
        </div>
        <div className={classes.tableWrapper}>
          <table className={classes.tableHeader}>
            <tbody>
              <tr className={classes.devicesArr}>
                <th>List Name</th>
                <th className={classes.URL}>URL</th>
                <th>Date Added</th>
                <th className={classes.status}>Status</th>
                <th>Details</th>
              </tr>
            </tbody>
            {m3u ? (
              m3u.map((ele, index) => {
                if (ele) {
                  return (
                    <tbody key={ele.id}>
                      <tr className={classes.rows}>
                        <td className={classes.deviceName}>
                          {ele.source.alias ? (
                            <input
                              disabled='disabled'
                              className={ele.flag ? classes.greenName : null}
                              defaultValue={
                                ele.source.alias
                                  ? ele.source.alias
                                  : ele.source.username
                              }
                              onBlur={(event) =>
                                m3uNameChangeHandler(
                                  event,
                                  props.eleInfo.deviceId,
                                  ele.source.url || ele.source.domain
                                )
                              }
                            ></input>
                          ) : (
                            ele.source.username
                          )}
                          {ele.source.alias && (
                            <button
                              onClick={focusM3UOnInputClick}
                              title='Change M3U list name'
                              className={classes.changeM3UName}
                            >
                              <i className='icon-edit-button'></i>
                            </button>
                          )}
                        </td>
                        <td
                          title={ele.source.url || ele.source.domain}
                          className={classes.URL}
                        >
                          {ele.source.url || ele.source.domain}
                        </td>
                        <td style={{ whiteSpace: "pre-line" }}>
                          {formattedDate(ele.source.creationDate)}
                        </td>
                        <td
                          className={
                            ele.flag ? classes.active : classes.deactive
                          }
                        >
                          {ele.flag ? "Active" : "Inactive"}
                        </td>
                        <td className={classes.desktopActions}>
                          <label className={classes.switch}>
                            <input
                              type='checkbox'
                              checked={ele.flag}
                              onChange={(event) => {
                                toggleSwitchTrue(
                                  event,
                                  ele.source.url,
                                  !!ele.source.domain
                                );
                              }}
                            ></input>
                            <span className={classes.sliderRound}></span>
                          </label>
                          <button
                            title='Remove the List'
                            className='icon-remove'
                            onClick={() => {
                              showDeleteModalHandler(ele);
                              setComingFrom("m3u");
                            }}
                          ></button>
                          <button
                            title='Refresh content'
                            className={`${"icon-loop"} ${
                              classes.refreshButton
                            }`}
                            onClick={() => refreshButtonHandler()}
                          ></button>
                        </td>
                        <td className={classes.mobileActions}>
                          <div className={classes.dropdown} id='dropdown'>
                            <button
                              className={classes.dropdownButton}
                              onClick={(event) => dropDownButton(index, event)}
                              id={`dropdownButton${index}`}
                            >
                              ···
                            </button>
                            <div
                              className={classes.dropdownContent}
                              id={`dropdownContent${index}`}
                            >
                              <div className={classes.switchWrapper}>
                                <label className={classes.switchMobile}>
                                  <input
                                    type='checkbox'
                                    checked={ele.flag}
                                    onChange={(event) => {
                                      toggleSwitchTrue(
                                        event,
                                        ele.source.url,
                                        !!ele.source.domain
                                      );
                                    }}
                                  ></input>
                                  <span
                                    className={classes.sliderRoundMobile}
                                  ></span>
                                </label>
                                <span
                                  className={
                                    ele.flag
                                      ? classes.activeMobile
                                      : classes.deactiveMobile
                                  }
                                >
                                  {ele.flag ? "Active" : "Inactive"}
                                </span>
                              </div>
                              <div className={classes.refreshWrapper}>
                                <button
                                  title='Refresh content'
                                  className='icon-loop'
                                  onClick={() => refreshButtonHandler()}
                                ></button>
                                <span className={classes.refreshSpan}>
                                  Refresh
                                </span>
                              </div>
                              <div className={classes.deleteWrapper}>
                                <button
                                  title='Remove the List'
                                  className='icon-remove'
                                  onClick={() => showDeleteModalHandler(ele)}
                                ></button>
                                <span>Delete</span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  );
                }
              })
            ) : (
              <tbody>
                <tr>
                  <td>
                    <h1 className={classes.emptyList}>
                      M3U list is empty! Please Add some Lists
                    </h1>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>

      <div className={classes.contentWrapper}>
        <div className={classes.headerWrapper}>
          <div className={classes.iconWrapper}>
            <i className='icon-Hamburger'></i>
            <h1 className={classes.h1}>Radios List</h1>
          </div>
          <div className={classes.buttonWrapperBottom}>
            <button
              className={classes.purpleButton}
              onClick={showRadioModalButtonHandler}
            >
              Add Radios
            </button>
          </div>
        </div>
        {!isLoading && (
          <div className={classes.tableWrapper}>
            <table className={classes.tableHeader}>
              <tbody>
                <tr className={classes.devicesArr}>
                  <th>Radio Name</th>
                  <th className={classes.URL}>Source</th>
                  <th style={{ textAlign: "center", paddingRight: 0 }}>
                    Radio Icon
                  </th>
                  <th style={{ textAlign: "center", paddingRight: 0 }}>
                    Details
                  </th>
                </tr>
              </tbody>
              {allRadios ? (
                allRadios.map((ele, index) => {
                  if (ele) {
                    return (
                      <tbody key={ele.id}>
                        <tr className={classes.rows}>
                          <td className={classes.deviceName}>
                            <input
                              id='inputName'
                              disabled='disabled'
                              className={classes.greenName}
                              defaultValue={ele.name}
                              onBlur={(event) =>
                                radioNameChangeHandler(
                                  event,
                                  ele.id,
                                  ele.name,
                                  ele.streamIcon,
                                  ele.streamSource
                                )
                              }
                            ></input>
                            {/* <button
                              onClick={focusM3UOnInputClick}
                              title='Change M3U list name'
                              className={classes.changeM3UName}
                            >
                              <i className='icon-edit-button'></i>
                            </button> */}
                          </td>
                          <td title={ele.streamSource} className={classes.URL}>
                            {ele.streamSource}
                          </td>
                          <td title={ele.streamIcon}>
                            <img
                              src={ele.streamIcon}
                              alt='Radio icon'
                              style={{
                                height: "40px",
                                width: "50px",
                                display: "block",
                                margin: "auto",
                              }}
                            ></img>
                          </td>
                          <td
                            className={classes.desktopActions}
                            style={{ textAlign: "center" }}
                          >
                            <label className={classes.switch}>
                              <input
                                type='checkbox'
                                checked={ele.isActive}
                                onChange={(event) => {
                                  toggleRadio(event, ele.name);
                                }}
                              ></input>
                              <span className={classes.sliderRound}></span>
                            </label>
                            <button
                              title='Remove the List'
                              className='icon-remove'
                              onClick={() => {
                                showDeleteModalHandler(ele);
                                setComingFrom("radios");
                              }}
                            ></button>
                          </td>
                          <td
                            className={classes.mobileActions}
                            style={{ textAlign: "center" }}
                          >
                            <div className={classes.deleteWrapper}>
                              <label className={classes.switchMobile}>
                                <input
                                  type='checkbox'
                                  checked={ele.flag}
                                  onChange={(event) => {
                                    toggleRadio(event, ele.name);
                                  }}
                                ></input>
                                <span className={classes.sliderRound}></span>
                              </label>
                              <button
                                title='Remove the List'
                                className='icon-remove'
                                onClick={() => {
                                  showDeleteModalHandler(ele);
                                  setComingFrom("radios");
                                }}
                              ></button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    );
                  }
                })
              ) : (
                <tbody>
                  <tr>
                    <td>
                      <h1 className={classes.emptyList}>
                        Radios list is empty! Please Add some.
                      </h1>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        )}
      </div>
      <AddRadioModal
        deviceId={deviceId}
        showRadioModal={showRadioModal}
        onCloseRadio={() => setShowRadioModal(false)}
      />
      <AddModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        deviceId={deviceId}
      />
      <PlanModal
        deviceId={deviceId}
        showPlanModal={showPlanModal}
        onClosePlan={() => setShowPlanModal(false)}
        eleInfo={props.eleInfo}
      />
      <DeleteModal
        comingFrom={comingFrom}
        radiosInfo={radiosInfo}
        showDeleteModal={showDeleteModal}
        onCloseDeleteModal={() => setShowDeleteModal(false)}
        m3UInfo={m3UInfo}
        deviceid={deviceId}
        message={`Are you sure you want to delete this list?`}
        onDeleteM3U={deleteM3UModalHandler}
      />
      <AddXtreamModal
        deviceId={deviceId}
        showXtreamModal={showXtreamModal}
        onCloseXtreamModal={() => setShowXtreamModal(false)}
        deviceid={deviceId}
      />
      <ReactTooltip
        type='light'
        textColor='black'
        backgroundColor='white'
        multiline={true}
        delayShow={100}
      />
    </div>
  );
};

export default DeviceDetails;
