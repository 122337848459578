import * as actionTypes from "./actionTypes";
import { axiosInstance } from "../../helpers/utility";
import { history } from "../../index";
import { baseURL } from "../../helpers/utility";
import axios from "axios";
import notify from "../../helpers/toast";
import { emailAlreadyInUse } from "../../helpers/errorMessages";

export const login = (email, password, deviceId, captchaToken) => {
  return async (dispatch) => {
    const data = { email, password, deviceId };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .post(
        //add captcha toke for production
        `${baseURL}v1/auth/login/`,
        {
          data,
        },
        config
      )
      .then((res) => {
        console.log("SUCCESS RESPONSE: ", res);
        dispatch(setUserData(res.data));
        const userData = res.data;
        localStorage.setItem("auth", JSON.stringify(userData));
        history.push("/devices-dashboard");
      })
      .catch((err) => {
        // console.log(err.response.data.message);
        notify({ text: err.response.data.message });
        dispatch(setErrorCode(err.response.status));
        console.log("ERROR RESPONSE: ", err);
      });
  };
};

export const resendEmail = (email) => {
  return async () => {
    const data = { email };
    console.log(data);
    axiosInstance
      .post(`/v1/auth/resendEmail`, {
        data,
      })
      .then((res) => {
        console.log("SUCCESS RESENDING EMAIL RESPONSE: ", res);
        notify({ text: "Email resent successfully!" });
      })
      .catch((err) => {
        console.log("RESENDING EMAIL ERROR RESPONSE: ", err);
      });
  };
};

export const passwordChange = (email) => {
  return async (dispatch) => {
    const data = { email };
    console.log(data);
    axiosInstance
      .post(`v1/auth/requestPasswordChange`, {
        data,
      })
      .then((res) => {
        console.log("SUCCESS PASSWORD CHANGE RESPONSE: ", res);
        notify({ text: "Confirmation mail sent successfully!" });
      })
      .catch((err) => {
        notify({ text: err.response.data.message });
        console.log("PASSWORD CHANGE ERROR RESPONSE: ", err);
      });
  };
};

export const passwordChangeVerification = ({ token }, newPassword) => {
  return async (dispatch) => {
    const data = { newPassword };
    axiosInstance
      .post(`/v1/auth/passwordChangeVerification/${token}`, {
        data,
      })
      .then((res) => {
        notify({ text: "Password changed successfully!" });
        console.log("SUCCESS PASSWORD VERIFICATION RESPONSE: ", res);
        history.push("/");
      })
      .catch((err) => {
        notify({ text: err.response.data.message });
        console.log("ACCOUNT PASSWORD VERIFICATION RESPONSE: ", err);
      });
  };
};

export const changePasswordSettings = (oldPassword, newPassword) => {
  return async (dispatch) => {
    const data = { oldPassword, newPassword };
    axiosInstance
      .post(`/v1/users/changePassword`, {
        data,
      })
      .then((res) => {
        console.log("SUCCESS PASSWORD CHANGE RESPONSE: ", res);
        notify({ text: "Password changed successfully!" });
      })
      .catch((err) => {
        console.log(
          "PASSWORD CHANGE ERROR RESPONSE: ",
          err.response.data.message
        );
        notify({ text: err.response.data.message });
      });
  };
};
export const upadateUserDetails = (firstName, lastName) => {
  const data = { firstName, lastName };
  return async (dispatch) => {
    axiosInstance
      .post(`/v1/auth/updateUserDetails`, {
        data,
      })
      .then((res) => {
        console.log("SUCCESS UPDATE USER DETAILS RESPONSE: ", res);
        // dispatch(setUserData({ ...userData, ...res.data }));
      })
      .catch((err) => {
        console.log("UPDATE USER DETAILS ERROR RESPONSE: ", err);
      });
  };
};

export const subscribeDevice = (deviceId, subscriptionName) => {
  const data = { deviceId, subscriptionName };
  return async (dispatch) => {
    axiosInstance
      .post(`/v1/subscription/subscribeDevice`, {
        data,
      })
      .then((res) => {
        dispatch(getDevices());
        console.log("SUCCESS ADDING SUBSCRIPTION RESPONSE: ", res);
        notify({ text: "Subscription added successfully!" });
      })
      .catch((err) => {
        console.log("ADDING SUBSCRIPTION ERROR RESPONSE: ", err);
      });
  };
};

export const getUserDetails = () => {
  return async (dispatch) => {
    axiosInstance
      .get(`/v1/users/getUserDetails`)
      .then((res) => {
        dispatch(setUserData(res.data));
        const local = localStorage.getItem("auth");
        const data = JSON.parse(local);
        const auth = {
          firstName: res.data.firstName ? res.data.firstName : data.firstName,
          lastName: res.data.lastName ? res.data.lastName : data.lastName,
          credits: res.data.credits,
          email: res.data.email,
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        };
        localStorage.setItem("auth", JSON.stringify(auth));
        console.log("SUCCESS GETTING USER DETAILS RESPONSE: ", res);
      })
      .catch((err) => {
        console.log("GETTING USER DETAILS ERROR RESPONSE: ", err);
      });
  };
};
export const getAllEnabledSubscriptions = () => {
  return async (dispatch) => {
    axiosInstance
      .get(`/v1/subscription/getAllEnabledSubscriptions`)
      .then((res) => {
        console.log("SUCCESS GETTING USER SUBSCRIPTIONS: ", res);
        dispatch(setPlans(res.data));
      })
      .catch((err) => {
        console.log("GETTING USER SUBSCRIPTION ERROR RESPONSE: ", err);
      });
  };
};

export const accountVerification = () => {
  return async (dispatch) => {
    axiosInstance
      .get(`v1/auth/accountVerification`)
      .then((res) => {
        console.log("SUCCESS ACCOUNT VERIFICATION RESPONSE: ", res);
      })
      .catch((err) => {
        console.log("ACCOUNT VERIFICATION ERROR RESPONSE: ", err);
      });
  };
};

export const getDevices = () => {
  return async (dispatch) => {
    axiosInstance
      .get(`v1/devices/getUserDevices`)
      .then((res) => {
        console.log("SUCCESS ACCOUNT DEVICES RESPONSE: ", res);
        dispatch(setDevices(res.data));
      })
      .catch((err) => {
        console.log("ACCOUNT DEVICES ERROR RESPONSE: ", err);
      });
  };
};

export const deleteDevice = (deviceId) => {
  const data = { deviceId };
  return async (dispatch) => {
    axiosInstance
      .post(`/v1/devices/deactivateDevice`, {
        data,
      })
      .then((res) => {
        notify({ text: "Device deleted successfully!" });
        dispatch(getDevices());
      })
      .catch((err) => {
        console.log("DELETE DEVICES ERROR RESPONSE: ", err);
      });
  };
};

export const createAccount = (
  firstName,
  lastName,
  email,
  password,
  deviceId,
  captchaToken
) => {
  const data = {
    deviceId,
    firstName,
    lastName,
    email,
    password,
  };
  return async (dispatch) => {
    axios
      .post(`${baseURL}v1/auth/signup/`, {
        data,
      })
      .then((res) => {
        console.log("SUCCESS CREATE ACCOUNT RESPONSE: ", res);
        dispatch(setSuccessful(false));
      })
      .catch((err) => {
        console.log(err.response);
        const errorMessage = err.response.data.message;
        dispatch(setErrorCode(err.response.data.message));
        if (errorMessage) {
          emailAlreadyInUse();
        }
        console.log("ERROR CREATE ACCOUNT RESPONSE: ", err);
      });
  };
};

export const setUserData = (userData) => {
  return {
    type: actionTypes.SET_USER_DATA,
    userData,
  };
};
export const setDevices = (devicesAll) => {
  return {
    type: actionTypes.SET_DEVICES,
    devicesAll,
  };
};

export const setErrorCode = (errorCode) => {
  return {
    type: actionTypes.SET_ERROR_CODE,
    errorCode,
  };
};
export const setSuccessful = (isSuccessful) => {
  return {
    type: actionTypes.SET_SUCCESS_REGISTRATION,
    isSuccessful,
  };
};

export const setPlans = (plansList) => {
  return {
    type: actionTypes.SET_PLANS,
    plansList,
  };
};
