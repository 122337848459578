import React, { useRef } from "react";
import classes from "../../../styles/components/AddModal.module.css";
import { Fragment, useEffect } from "react";
import { putM3U } from "../../../store/actions/devices";
import { useDispatch } from "react-redux";
import notify from "../../../helpers/toast";

const AddModal = (props) => {
  const aliasInputRef = useRef();
  const urlInputRef = useRef();
  const dispatch = useDispatch();

  const closeOnEscapeButton = (e) => {
    if (e.keyCode === 27) {
      props.onClose();
    }
  };
  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeButton);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeButton);
    };
  });
  useEffect(() => {
    document.body.addEventListener("keydown", addOnEnterButton);
    return function cleanup() {
      document.body.removeEventListener("keydown", addOnEnterButton);
    };
  });
  const addOnEnterButton = (e) => {
    if (props.showModal) {
      if (e.keyCode === 13) {
        addM3UButtonHandler();
      }
    }
  };

  const closeModalHandler = () => {
    props.onClose();
  };

  if (!props.showModal) {
    return null;
  }

  const deviceId = props.deviceId;

  console.log(deviceId);

  const addM3UButtonHandler = () => {
    const alias = aliasInputRef.current.value;
    const url = urlInputRef.current.value;
    if (!alias || !url) {
      notify({ text: "Please fill all the input fields" });
    } else {
      dispatch(putM3U(alias, url, deviceId));
      props.onClose();
    }
  };

  const closeOnXButtonHandler = () => {
    props.onClose();
  };

  return (
    <Fragment>
      <div className={classes.modalBackground}>
        <div className={classes.modalWrapper}>
          <button className={classes.xButton} onClick={closeOnXButtonHandler}>
            X
          </button>
          <h1 className={classes.h1}>Add M3U List</h1>
          <div className={classes.inputWrapper}>
            <label>List Name</label>
            <input ref={aliasInputRef}></input>
          </div>
          <div className={classes.inputWrapper}>
            <label>List URL</label>
            <input ref={urlInputRef}></input>
          </div>
          <div className={classes.buttonWrapper}>
            <button className={classes.button1} onClick={closeModalHandler}>
              Cancel
            </button>
            <button className={classes.button2} onClick={addM3UButtonHandler}>
              Add List
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddModal;
