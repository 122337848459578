import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { user, userData, devicesAll, m3uXtreamList,errorCode, isSuccessful,plansList,radiosList, isLoading } from "./reducers/user";




const rootReducer = combineReducers({
  user,
  userData,
  devicesAll,
  m3uXtreamList,
  errorCode,
  isSuccessful,
  plansList,
  radiosList,
  isLoading,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));


