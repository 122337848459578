import React from "react";
import CreateAccount from "../components/LoginWrapper/CreateAccount";
import LoginSidebar from "../components/LoginWrapper/LoginSidebar";

const CreateAccountPage = () => {
    return (
        <LoginSidebar>
            <CreateAccount/>
        </LoginSidebar>
    )
};

export default CreateAccountPage;