import React, {
  Fragment,
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import mainLogo from "../../assets/images/logo-full.png";
import classes from "../../styles/components/LoginCard.module.css";
import { passwordVisibilityToggle } from "../../helpers/passwordVisibilityToggle";
import { useDispatch, useSelector } from "react-redux";
import { login, resendEmail, setErrorCode } from "../../store/actions/user";
import { ToastContainer } from "react-toastify";
import {
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const LoginCard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const { id } = useParams();
  let errorCode = useSelector((state) => state.errorCode);
  const [loginScreen, setLoginScreen] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [captchaToken, setCaptchaToken] = useState("");

  const createAccountButtonHandler = () => {
    history.push("/create-account");
  };
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Recaptcha not executed");
      return;
    }
    const token = await executeRecaptcha("login");
    setCaptchaToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const logInButtonHandler = () => {
    let deviceId = id ? id : null;
    const email = emailInputRef.current.value;
    const password = passwordInputRef.current.value;
    dispatch(login(email, password, deviceId, captchaToken));
    dispatch(setErrorCode(0));
    console.log(captchaToken);
  };

  useEffect(() => {
    if (!errorCode || errorCode === 401) {
      setLoginScreen("");
    } else if (errorCode && errorCode === 454) {
      setLoginScreen(emailInputRef.current.value);
    }
  }, [errorCode]);

  const passwordChangeButtonHandler = () => {
    history.push("/password-reset");
  };

  const logInOnEnterPress = (event) => {
    if (event.keyCode === 13) {
     logInButtonHandler();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", logInOnEnterPress);
    return function cleanup() {
      document.body.removeEventListener("keydown", logInOnEnterPress);
    };
  });

  const backButtonHandler = () => {
    setLoginScreen("");
    dispatch(setErrorCode(0));
  };

  const resendEmailButtonHandler = () => {
    dispatch(resendEmail(loginScreen));
    dispatch(setErrorCode(0));
    setLoginScreen("");
  };

  return (
    <Fragment>
      {!loginScreen ? (
        <section onKeyDown={logInOnEnterPress}>
          <div className={classes.mobileLogoWrapper}>
            <img src={mainLogo} alt="logo" className={classes.logo}></img>
          </div>
          <div className={classes.cardWrapper}>
            <div className={classes.contentWrapper}>
              <h1 className={classes.login}>Login</h1>
              <div className={classes.inputWrapper}>
                <label htmlFor="email" required>
                  Email
                </label>
                <div>
                  <input
                    type="email"
                    required
                    id="email"
                    ref={emailInputRef}
                    autoComplete="off"
                  ></input>
                </div>
              </div>
              <div className={classes.inputWrapper}>
                <label htmlFor="password" required>
                  Password
                </label>
                <div className={classes.inputWrapperInput}>
                  <input
                    type="password"
                    required
                    id="password"
                    name="password"
                    ref={passwordInputRef}
                    className="password-input"
                    autoComplete="nope"
                  ></input>
                  <i
                    className="icon-eye-blocked"
                    id="togglePassword"
                    onClick={passwordVisibilityToggle}
                  ></i>
                </div>
              </div>

              <div className={classes.buttonWrapper}>
                <button className={classes.logIn} onClick={logInButtonHandler}>
                  Log in
                </button>
                <button
                  className={classes.forgotPassword}
                  onClick={passwordChangeButtonHandler}
                >
                  Forgot password?
                </button>
              </div>
              <div
                className={
                  errorCode === 403 ? classes.errorBlock : classes.noErrorBlock
                }
                id="error-block"
              >
                <i className="icon-errorFlag"></i>
                <p className={classes.errorParagraph}>
                  The email or password you entered is incorrect.
                </p>
              </div>
              <div className={classes.button3}>
                <button
                  className={classes.registerButton}
                  onClick={createAccountButtonHandler}
                >
                  You do not have an account? <br />
                  Register Now!
                </button>
              </div>
            </div>
          </div>
        </section>
      ) : null}
      {!!loginScreen ? (
        <div className={classes.sCardWrapper}>
          <div className={classes.sContentWrapper}>
            <div>
              <h1 className={classes.notVerified}>Email is not verified</h1>
            </div>
            <div className={classes.pWrapper}>
              <p>
                Email address you provided is not verified. Please click “Resend
                email” button, to send an email with verification link again.
              </p>
            </div>
            <div className={classes.sButtonWrapper}>
              <button
                className={classes.blackButton}
                onClick={backButtonHandler}
              >
                Back
              </button>
              <button
                className={classes.whiteButton}
                onClick={resendEmailButtonHandler}
              >
                Resend email
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <form id="form_id" method="post" action="your_action.php">
        <input
          type="hidden"
          id="g-recaptcha-response"
          name="g-recaptcha-response"
        ></input>
        <input type="hidden" name="action" value="validate_captcha"></input>
      </form>
      <ToastContainer />
    </Fragment>
  );
};

export default LoginCard;
