import * as actionTypes from "../actions/actionTypes";

export const user = (state = "", action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return action.user;
    default:
      return state;
  }
};

export const m3uXtreamList = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_M3U_XTREAM_LIST:
      return action.m3uXtreamList;
    default:
      return state;
  }
};

export const userData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_DATA:
      return action.userData;
    default:
      return state;
  }
};
export const devicesAll = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_DEVICES:
      return action.devicesAll;
    default:
      return state;
  }
};

export const errorCode = (state = "", action) => {
  switch (action.type) {
    case actionTypes.SET_ERROR_CODE:
      return action.errorCode;
    default:
      return state;
  }
};

export const isSuccessful = (state = true, action) => {
  switch (action.type) {
    case actionTypes.SET_SUCCESS_REGISTRATION:
      return action.isSuccessful;
    default:
      return state;
  }
};
export const plansList = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_PLANS:
      return action.plansList;
    default:
      return state;
  }
};

export const radiosList = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_RADIOS:
      return action.radiosList;
    default:
      return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}
