import React from "react";
import PasswordConfirmation from "../components/LoginWrapper/PasswordConfirmation";
import LoginSidebar from "../components/LoginWrapper/LoginSidebar";

const PasswordConfirmationPage = () => {
  return (
    <LoginSidebar>
      <PasswordConfirmation />
    </LoginSidebar>
  );
};

export default PasswordConfirmationPage;
