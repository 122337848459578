import React from 'react'
import LoginSidebar from '../components/LoginWrapper/LoginSidebar'
import PasswordChange from '../components/LoginWrapper/PasswordChange'

export const PasswordChangePage = () => {
  return (
    <LoginSidebar>
      <PasswordChange />
    </LoginSidebar>
  );
}
