import React, { Fragment, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import CreateAccountPage from "./pages/CreateAccountPage";
import VerificationPage from "./pages/VerificationPage";
import DevicesDashboardPage from "./pages/DevicesDashboardPage";
import CreditDashboardPage from "./pages/CreditDashboardPage";
import SettingsDashboardPage from "./pages/SettingsDashboardPage";
import { PasswordChangePage } from "./pages/PasswordChangePage";
import PasswordConfirmationPage from "./pages/PasswordConfirmationPage";
import { getUserDetails } from "./store/actions/user";
import { ToastContainer } from "react-toastify";
import "./assets/Icomoon/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

function App() {
  const userData = useSelector((state) => state.userData);
  const dispatch = useDispatch();

  useEffect(()=> {
    dispatch(getUserDetails());
  },[])


  return (
    <Fragment>
      <Switch>
        <Route path="/" exact>
          <LoginPage />
        </Route>
        <Route path="/create-account/:id?">
          <CreateAccountPage />
        </Route>
        <Route path="/password-reset" exact>
          <PasswordChangePage />
        </Route>
        <Route path="/verification" exact>
          <VerificationPage />
        </Route>
        <Route path="/devices-dashboard" exact>
          {Object.entries(userData).length ? (
            <DevicesDashboardPage />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        <Route path="/credit-dashboard" exact>
          {Object.entries(userData).length ? (
            <CreditDashboardPage />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        <Route path="/settings-dashboard" exact>
          {Object.entries(userData).length ? (
            <SettingsDashboardPage />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        <Route path="/password-confirmation/:token" exact>
          <PasswordConfirmationPage />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
      <ToastContainer />
    </Fragment>
  );
}

export default App;
