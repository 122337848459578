import React, { useEffect, useRef } from "react";
import classes from "../../../styles/components/AddXtreamModal.module.css";
import { postRadio } from "../../../store/actions/devices";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import notify from "../../../helpers/toast";

const AddRadioModal = (props) => {
  const dispatch = useDispatch();
  const nameInputRef = useRef();
  const streamIconInputRef = useRef();
  const streamSourceInputRef = useRef();

  const closeModalHandler = () => {
    props.onCloseRadio();
  };
  const closeOnEscapeButton = (e) => {
    if (e.keyCode === 27) {
      props.onCloseRadio();
    }
  };
  const addOnEnterButton = (e) => {
    if (props.showRadioModal) {
      if (e.keyCode === 13) {
        addRadioButtonHandler();
      }
    }
  };
  useEffect(() => {
    document.body.addEventListener("keydown", addOnEnterButton);
    return function cleanup() {
      document.body.removeEventListener("keydown", addOnEnterButton);
    };
  });
  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeButton);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeButton);
    };
  });
  if (!props.showRadioModal) {
    return null;
  }
  const addRadioButtonHandler = () => {
    const name = nameInputRef.current.value;
    const streamIcon = streamIconInputRef.current.value;
    const streamSource = streamSourceInputRef.current.value;
    if (!name || !streamIcon || !streamSource) {
      if (props.showXtreamModal) {
        notify({ text: "Please fill all the input fields." });
      }
    } else {
      dispatch(
        postRadio(
          props.deviceId,
          name,
          streamIcon,
          streamSource,
        )
      );
      props.onCloseRadio();
    }
  };
  const xButtonHandler = () => {
    props.onCloseRadio();
  };

  return (
    <div className={classes.modalBackground} id="xtreamModal">
      <div className={classes.modalWrapper}>
        <div className={classes.contentWrapper}>
          <div>
            <button className={classes.xButton} onClick={xButtonHandler}>
              X
            </button>
            <h1 className={classes.h1}>Add Radio</h1>
          </div>
          <div className={classes.inputWrapper}>
            <label>Name</label>
            <input type="username" ref={nameInputRef}></input>
          </div>
          <div className={classes.inputWrapper}>
            <label>Stream icon</label>
            <input type="username" ref={streamIconInputRef}></input>
          </div>
          <div className={classes.inputWrapper}>
            <label>Stream source</label>
            <input ref={streamSourceInputRef}></input>
          </div>
          <div className={classes.buttonWrapper}>
            <div>
              <button
                className={classes.blackButton}
                onClick={closeModalHandler}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className={classes.purpleButton}
                onClick={addRadioButtonHandler}
              >
                Add Radio
              </button>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
};

export default AddRadioModal;