import React, { useState } from "react";
import classes from "../../styles/components/DashboardCredit.module.css";
import BuyCreditModal from "./Modals/BuyCreditModal";

const DashboardCredit = () => {
  const [showModal, setShowModal] = useState(false);
  const local = localStorage.getItem("auth");
  const data = JSON.parse(local);

  const showModalHandler = () => {
    setShowModal(true);
  };


  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.headerWrapper}>
          <div className={classes.header}>
            <i className="icon-subription"></i>
            <h1 className={classes.h1}>Credit</h1>
          </div>
          <div>
            <button className={classes.purpleButton} onClick={showModalHandler}>
              Buy credit
            </button>
          </div>
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.creditH1}>
            <h1>Your balance is: </h1>
          </div>
          <div className={classes.credit}>
            <h1>{data && data.credits}</h1>
          </div>
        </div>
        <BuyCreditModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
        />
      </div>
    </div>
  );
};

export default DashboardCredit;
