export const emailAlreadyInUse = () => {
  const label = document.getElementById("email-label");
  const paragraph = document.getElementById("error-paragraph");
  const input = document.getElementById("email-input");
  if (input) {
    input.style.border = "2px solid #BD3134";
  }
  if (paragraph) {
    paragraph.style.display = "flex";
  }
  if (label) {
    label.style.color = "#BD3134";
  }
  else {
    return;
  }
};
