import React, { useRef,useEffect } from "react";
import classes from "../../styles/components/PasswordChange.module.css";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { passwordChange } from "../../store/actions/user";
import mainLogo from "../../assets/images/logo-full.png";

const PasswordChange = () => {
  const history = useHistory();
  const emailInputRef = useRef();
  const dispatch = useDispatch();

  const backButtonHandler = () => {
    history.replace("/");
  };

  const passwordChangeButtonHandler = () => {
    const email = emailInputRef.current.value;
    dispatch(passwordChange(email));
  };
  const confirmOnEnterPress = (event) => {
    if (event.keyCode === 13) {
      passwordChangeButtonHandler();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", confirmOnEnterPress);
    return function cleanup() {
      document.body.removeEventListener("keydown", confirmOnEnterPress);
    };
  });

  return (
    <section>
      <div className={classes.mobileLogoWrapper}>
        <img src={mainLogo} alt="logo" className={classes.logo}></img>
      </div>
      <div className={classes.cardWrapper}>
        <div className={classes.contentWrapper}>
          <div>
            <h1 className={classes.h1}>Password Change</h1>
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="name" required>
              Email
            </label>
            <input
              type="email"
              required
              id="firstName"
              ref={emailInputRef}
            ></input>
          </div>
          <div className={classes.buttonWrapper}>
            <button className={classes.backButton} onClick={backButtonHandler}>
              Back
            </button>
            <button
              className={classes.whitePurpleButton}
              onClick={passwordChangeButtonHandler}
            >
              Confirm
            </button>
          </div>
        </div>
      </div> 
    </section>
  );
};

export default PasswordChange;
