export const formattedDate = (date) => {
  if(!date) return "";
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };
  const format = new Date(date);
  const dateArray = new Intl.DateTimeFormat("en-GB", options)
    .format(format)
    .split(`,`);
  return `${dateArray[0]}
  ${dateArray[1]}`;
};
