import React from "react";
import { useDispatch } from "react-redux";
import { deleteRadio } from "../../../store/actions/devices";
import classes from "../../../styles/components/DeleteModal.module.css";


const DeleteModal = (props) => {
  const dispatch = useDispatch();
  if (!props.showDeleteModal) {
    return null;
  }

  const closeModalHandler = () => {
    props.onCloseDeleteModal();
  };

  const deleteListButtonHandler = () => {
    if (props.deleteDevices) {
      props.deleteDevices();
    } 
    else if (props.comingFrom === 'radios') {
      dispatch(deleteRadio(props.deviceid, props.radiosInfo.id))
    }
    else if(props.comingFrom === 'm3u') {
      props.onDeleteM3U();
    }

    props.onCloseDeleteModal();
  };

  return (
    <div className={classes.modalBackground}>
      <div className={classes.modalWrapper}>
        <div className={classes.paragraph}>
          <p>
            {props.message}
            {props.deviceMessage}
          </p>
        </div>
        <div className={classes.button}>
          <button className={classes.buttonWrapper} onClick={closeModalHandler}>
            No
          </button>
          <button
            className={classes.buttonWrapper}
            onClick={deleteListButtonHandler}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
