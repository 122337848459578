import React from "react";
import { Fragment } from "react";
import DashboardDevices from "../components/dashboard/DashboardDevices";
import DashboardLayout from "../components/dashboard/DashboardLayout/DashboardLayout";
import SidebarDashboard from "../components/dashboard/DashboardSidebar/SidebarDashboard";
import classes from "../styles/components/DashboardStyle.module.css"

const DevicesDashboardPage = () => {
  return (
    <Fragment>
      <DashboardLayout />
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className={classes.dashboardStyle}
      >
        <SidebarDashboard />
        <DashboardDevices />
      </div>
    </Fragment>
  );
};

export default DevicesDashboardPage;
