import React from "react";
import { Fragment } from "react";
import DashboardLayout from "../components/dashboard/DashboardLayout/DashboardLayout";
import DashboardSettings from "../components/dashboard/DashboardSettings";
import SidebarDashboard from "../components/dashboard/DashboardSidebar/SidebarDashboard";
import classes from "../styles/components/DashboardStyle.module.css";

const SettingsDashboardPage = () => {
  return (
    <Fragment>
      <DashboardLayout />
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className={classes.dashboardStyle}
      >
        <SidebarDashboard />
        <DashboardSettings />
      </div>
    </Fragment>
  );
};

export default SettingsDashboardPage;
