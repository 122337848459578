import React, { useEffect } from "react";
import classes from "../../../styles/components/BuyCreditModal.module.css";

const BuyCreditModal = (props) => {
  const closeOnEscapeButton = (e) => {
    if (e.keyCode === 27) {
      props.onClose();
    }
  };
  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeButton);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeButton);
    };
  });

  const closeModalHandler = () => {
    props.onClose();
  };

  if (!props.showModal) {
    return null;
  }

  return (
    <div className={classes.modalBackground}>
      <div className={classes.modalWrapper}>
        <h1 className={classes.h1}>New Payment Method</h1>
        <div className={classes.cardholderName}>
          <label>Cardholder Name:</label>
          <input></input>
        </div>
        <div className={classes.cardNumberLabel}>
          <label>Card Number:</label>
        </div>
        <div className={classes.cardNumber}>
          <input maxLength={4} type="number"></input>
          <div>
            <span></span>
          </div>
          <input maxLength={4} type="number"></input>
          <div>
            <span></span>
          </div>
          <input maxLength={4} type="number"></input>
          <div>
            <span></span>
          </div>
          <input maxLength={4} type="number"></input>
        </div>
        <div className={classes.expDate}>
          <label>Exp Date:</label>
        </div>
        <div className={classes.cardNumber2}>
          <input maxLength={2} type="number"></input>
          <div>
            <span></span>
          </div>
          <input maxLength={4} type="number"></input>
          <div className={classes.cvv}>
            <label>CVV</label>
            <input maxLength={3} type="number"></input>
          </div>
        </div>
        <div className={classes.buttonWrapper}>
          <button onClick={closeModalHandler} className={classes.cancelButton}>
            Cancel
          </button>
          <button className={classes.purpleButton}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default BuyCreditModal;
