import React, { useState } from "react";
import classes from "../../styles/components/DashboardSettings.module.css";
import EditSettingsModal from "./Modals/EditSettingsModal";

const DashboardSettings = () => {
  const [showModal, setShowModal] = useState(false);
  const local = localStorage.getItem("auth");
  const data = JSON.parse(local);

  const showModalHandler = () => {
    setShowModal(true);
  };

  return (
    <div className={classes.containter}>
      <div className={classes.wrapper}>
        <div className={classes.headerWrapper}>
          <div className={classes.header}>
            <i className="icon-Settings"></i>
            <h1 className={classes.h1}>Settings</h1>
          </div>
          <div className={classes.buttonWrapper}>
            <button className={classes.purpleButton} onClick={showModalHandler}>
              Edit
            </button>
          </div>
        </div>
        <div className={classes.contentWrapper}>
          <h1 className={classes.purple}>
            <b>Personal Information</b>
          </h1>

          <h1>
            First name: <b>{data && data.firstName}</b>
          </h1>
          <h1>
            Last name: <b>{data && data.lastName}</b>
          </h1>
          <h1>
            Email: <b>{data && data.email}</b>
          </h1>
        </div>
        <EditSettingsModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
        />
      </div>
    </div>
  );
};

export default DashboardSettings;
