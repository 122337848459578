import axios from "axios";
import classes from "../styles/components/LoadingSpinner.module.css";

//export const baseURL = `https://lumen-api.boopro.tech/`;
export const baseURL = `https://api.lumenplayer.com/`;

export const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
axiosInstance.interceptors.request.use(function (config) {
  document.body.classList.add(classes.loader);
  return config;
});
axiosInstance.interceptors.response.use(
  function (response) {
    document.body.classList.remove(classes.loader);
    return response;
  },
  function (error) {
    document.body.classList.remove(classes.loader);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(function (config) {
  const authData = JSON.parse(localStorage.getItem("auth"));

  if (authData) {
    config.headers.Authorization = `Bearer ${authData.accessToken}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    console.log(error);
    if (
      originalRequest.url !== "/v1/auth/login" &&
      error.response.status === 403 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const auth = await refreshAccessToken();
      console.log(auth);
      localStorage.setItem("auth", JSON.stringify(auth));
      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

const refreshAccessToken = async () => {
  const token = JSON.parse(localStorage.getItem("auth")).refreshToken;
  const res = await axiosInstance.post(`v1/auth/refreshToken`, {
    data: {
      refreshToken: token,
    },
  });
  const data = await res.data;
  const formatted = {
    token: data.accessToken,
    refreshToken: data.refreshToken,
    email: JSON.parse(localStorage.getItem("auth")).email,
  };
  return formatted;
};
