import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import mainLogo from "../../assets/images/logo-full.png";
import classes from "../../styles/components/CreateAccount.module.css";
import { passwordVisibilityToggle } from "../../helpers/passwordVisibilityToggle";
import { createAccount } from "../../store/actions/user";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const CreateAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const nameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordRef2 = useRef();
  const [disable, setDisable] = useState(false);
  const errorCode = useSelector((state) => state.errorCode);
  const [errorMessage, setErrorMessage] = useState("");
  const [successState, setSuccessState] = useState(false);
  const { id } = useParams();
  const success = useSelector((state) => state.isSuccessful);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [captchaToken, setCaptchaToken] = useState("");

  const backButtonHandler = () => {
    history.push("/");
  };
  console.log(success)

  const onCloseSuccessMessage = () => {
    history.push("/");
    setSuccessState(false);
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Recaptcha not executed");
      return;
    }
    const token = await executeRecaptcha("register");
    setCaptchaToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const displayErrorFun = (message, display) => {
    const errorBlock = document.getElementById("error-block");
    errorBlock.style.display = display;
    setErrorMessage(message);
    setTimeout(cancelErrorMessage, 3000);
  };

  const cancelErrorMessage = () => {
    const errorBlock = document.getElementById("error-block");
    errorBlock.style.display = "none";
  }

  const createAccountButtonHandler = () => {
    let firstName = nameRef.current.value;
    let lastName = lastNameRef ? lastNameRef.current.value : null;
    let email = emailRef ? emailRef.current.value : null;
    let password = passwordRef ? passwordRef.current.value : null;
    let repeatPassword = passwordRef2 ? passwordRef2.current.value : null;
    let deviceId = id ? id : null;

    if (!firstName || !lastName || !email || !password || !repeatPassword) {
      displayErrorFun("Please fill all the input fields.", "flex");
    } else if (password !== repeatPassword) {
      displayErrorFun("Passwords must match.", "flex");
    } else {
      dispatch(
        createAccount(
          firstName,
          lastName,
          email,
          password,
          deviceId,
          captchaToken
        )
      );
      setSuccessState(success);
    }
    setDisable(true);
    setTimeout(setDisable(false), 2000)
  };

  const submitFormOnEnterPress = (event) => {
    if(disable) {
      return;
    }
    if (event.keyCode === 13) {
      createAccountButtonHandler();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keypress", submitFormOnEnterPress);
    return function cleanup() {
      document.body.removeEventListener("keypress", submitFormOnEnterPress);
    };
  });

  return (
    <section onKeyDown={submitFormOnEnterPress} className={classes.section}>
      <div className={classes.mobileLogoWrapper}>
        <img src={mainLogo} alt="logo" className={classes.logo}></img>
      </div>
      {!successState ? (
        <>
          <div className={classes.cardWrapper}>
            <div className={classes.contentWrapper}>
              <div>
                <h5 className={classes.h3}>Create Account</h5>
              </div>
              <div className={classes.inputWrapper}>
                <label htmlFor="name" required>
                  First Name
                </label>
                <input
                  type="email"
                  ref={nameRef}
                  required
                  id="firstName"
                ></input>
              </div>
              <div className={classes.inputWrapper}>
                <label htmlFor="name" required>
                  Last Name
                </label>
                <input
                  type="email"
                  required
                  id="lastName"
                  ref={lastNameRef}
                ></input>
              </div>
              <div className={classes.inputWrapper}>
                <label htmlFor="email" required id="email-label">
                  Email
                </label>
                <input
                  type="email"
                  required
                  id="email-input"
                  ref={emailRef}
                ></input>
                <p className={classes.errorMessage} id="error-paragraph">
                  {errorCode}
                </p>
              </div>
              <div className={classes.inputWrapper}>
                <label htmlFor="password">Password</label>
                <input
                  className="password-input"
                  type="password"
                  required
                  id="password1"
                  ref={passwordRef}
                ></input>
                <i
                  className="icon-eye-blocked"
                  id="togglePassword"
                  onClick={passwordVisibilityToggle}
                ></i>
              </div>
              <div className={classes.inputWrapper}>
                <label htmlFor="password">Confirm password</label>
                <input
                  ref={passwordRef2}
                  type="password"
                  required
                  className="password-input"
                  id="password2"
                ></input>
                <i
                  className="icon-eye-blocked"
                  id="togglePassword"
                  onClick={passwordVisibilityToggle}
                ></i>
              </div>
              <div className={classes.errorBlock} id="error-block">
                <i className="icon-errorFlag"></i>
                <p className={classes.errorParagraph}>{errorMessage}</p>
              </div>
              <div className={classes.buttonWrapper}>
                <button
                  className={classes.backButton}
                  onClick={backButtonHandler}
                >
                  Back
                </button>
                <button
                  className={classes.createAccButton}
                  onClick={() => createAccountButtonHandler()}
                  id="button2"
                  disabled={disable}
                >
                  Create account
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={classes.cardWrapper}>
          <div>
            <h1 className={classes.successMessage}>Success</h1>
          </div>
          <div className={classes.pWrapper}>
            <p>
              Registration successful.
              <br /> You will shortly recieve an email from the service which
              will confirm your details. Click the link to activate your
              account.
            </p>
            <p>
              If you do not recieve this email, you can login and click the
              "resend email" button.
            </p>
          </div>
          <div className={classes.sButtonWrapper}>
            <button
              className={classes.closeButton}
              onClick={onCloseSuccessMessage}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default CreateAccount;
