import React from "react";
import HeaderDashboard from "../DashboardHeader/HeaderDashboard";
import { Fragment } from "react";

const DashboardLayout = () => {
  return (
    <Fragment>
      <HeaderDashboard />
    </Fragment>
  );
};

export default DashboardLayout;
