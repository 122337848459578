import React from "react";
import LoginSidebar from "../components/LoginWrapper/LoginSidebar";
import LoginCard from "../components/LoginWrapper/LoginCard"

const LoginPage = () => {
    return (
        <LoginSidebar>
            <LoginCard/>
        </LoginSidebar>
    )
};

export default LoginPage;